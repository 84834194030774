import React from 'react'
import { graphql, Link } from 'gatsby'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import HeroImage from '../components/contentful/hero-image'
import { queryParamsFromString, stringFromQueryParams, buildBreadcrumbsItem } from '../helpers' 
import Cart from '../components/cart/page-widget'

import styles from '../scss/page.scss'

class CartPage extends React.Component {  
  constructor(props) {
    super(props);
    
    const title = 'Cart | Honest Paws®'
    this.seo = {
      title: title,
      meta: {
        title: title
      },
      children: [],
      disallowIndexing: true
    }
    
    this.breadcrumbs = [
      buildBreadcrumbsItem('Cart', this.props.location.href)
    ]
  }
  
  render() {  
    const query = queryParamsFromString(this.props.location.search)
    let cartToken = null
    if (query.token) {
      cartToken = query.token
    }
    return (
      <Layout containerClassName={`page page-cart`} breadcrumbs={ this.breadcrumbs } location={this.props.location} >
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary page-header text-center px-4">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">My Cart</h1>       
            </div>
          </div>
        </section>
        <section className="bg-light text-center">
          <Cart token={cartToken} />
        </section>
      </Layout>
    )
  }
}

export default CartPage

export const CartPageQuery = graphql`
  query CartPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`