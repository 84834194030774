import React, { Component } from 'react';
import { navigate } from "gatsby"
import { getCartFromToken, removeCartFromToken, setCart, restoreCart } from './actions'
import { productCategoryUrl } from '../../helpers'
import { connect } from 'react-redux'

class PageWidget extends Component {
  constructor(props) {
    super(props)
    
    this.cart = getCartFromToken(this.props.token)
    
    this.populateCart = this.populateCart.bind(this)
  }
  
  /**
   * Populates cart and takes user to collections page
   * @return {void}
   */
  populateCart() {
    // setCart(this.cart)
    this.props.dispatch(restoreCart({
      // name: '',
      cart: {
        ...this.cart,
        token: this.props.token
      }
    }))
    // We want to remove the temporary cart we created
    removeCartFromToken(this.props.token)
    if (typeof window !== 'undefined') {
      // Cause "naviate" will fail due to window not existing during render
      navigate(`${productCategoryUrl()}?cart_open=true`)    
    }
  }
  
  render() {
    if (!this.cart) {
      if (typeof window !== 'undefined') {
        navigate(productCategoryUrl())
      }
    }
    return this.cart ? (
      <div className="cart-page-widget">
        <button className="btn btn-primary" onClick={this.populateCart}>Continue Shopping</button>
      </div>
    ) : ''  
  }
}

// export default PageWidget 

export default connect()(PageWidget)